import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const DrHemphillLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Hemphill"
    institution="City Pulse Acupuncture"
    referralCode="DRHEMPHILL"
    physicianURL="http://citypulseacupuncture.com/about/rachel-hemphill/"
  />
)

export default DrHemphillLandingPage
